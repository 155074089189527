import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import get from "lodash/get";
import Img from "gatsby-image";
import Layout from "../components/layout/layout";

class BlogPostTemplate extends React.Component {
  render() {
    const entry = get(this.props, "data.contentfulYearEntry");

    console.log(entry)

    return (

      <Layout>
<div className="gallery">
<h1>{entry.year}</h1>
        <div className='gallery-photo-grid' >
          {entry.assets.map(asset => {
            return (
              <Img alt={asset.title} fluid={asset.fluid} />
            )
          })}
        </div>
</div>
        
        
      </Layout>
    )
}
}

export default BlogPostTemplate;

export const pageQuery = graphql`
query YearEntryBySlug($year: Date!) {
    contentfulYearEntry(year: { eq: $year }) {
        year
        childContentfulYearEntryDescriptionTextNode {
            childMarkdownRemark {
            html
            }
        }
        assets {
            fluid(maxWidth: 1250, maxHeight: 1500) {
            ...GatsbyContentfulFluid_tracedSVG
            }
            title
        }
    }
  }
`;


